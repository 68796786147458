<template>
  <v-dialog
    v-model="dialog"
    width="1100"
    :persistent="isFormSending"
    :disabled="isFormSending"
    @click:outside="cancel"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card height="90vh" :disabled="isFormSending">
      <v-stepper v-model="step" class="team-adder-stepper">
        <v-stepper-items style="position: relative;">
          <v-stepper-content class="stepper-content bg-ease" step="1">
            <TeamAdderStep1 ref="step1" />
          </v-stepper-content>

          <v-stepper-content class="stepper-content" step="2">
            <TeamAdderStep2 ref="step2" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card-actions>
        <v-row class="px-8 py-4" no-gutters justify="space-between">
          <v-col cols="auto">
            <v-btn
              class="btn-cancel"
              :disabled="isFormSending"
              rounded
              @click="cancel"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="step > 1"
              class="mr-4"
              color="primary"
              :disabled="isFormSending"
              rounded
              @click="prevStep"
            >
              {{ $t('prev_step') }}
            </v-btn>
            <v-btn
              v-if="step < totalStep"
              color="primary"
              :disabled="isFormSending"
              rounded
              @click="nextStep"
            >
              {{ $t('next_step') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :loading="isFormSending"
              @click="submit"
            >
              <template v-if="isEditModel">
                <v-icon class="mr-2">
                  mdi-content-save
                </v-icon>
                <span>{{ $t('save') }}</span>
              </template>
              <span v-else>{{ $t('create') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TeamAdderStep1 from './TeamAdderStep1'
import TeamAdderStep2 from './TeamAdderStep2'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TeamAdder',

  components: {
    TeamAdderStep1,
    TeamAdderStep2
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    totalStep: 2,
    isSending: false
  }),

  computed: {
    ...mapState({
      isFormSending: (state) => state.teams.form.isSending
    }),
    ...mapGetters({
      isEditModel: 'teams/form/isEditModel'
    }),

    dialog: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    step: {
      get() {
        return this.$store.state.teams.form.step
      },
      set(newVal) {
        this.$store.dispatch('teams/form/setState', {
          step: newVal
        })
      }
    }
  },

  methods: {
    init() {
      this.step = 1

      this.$store.dispatch('teams/form/init')
      this.$store.dispatch('teams/clearMembers')

      this.$refs.step1.init()
      this.$refs.step2.init()

      // FIXME: Eslint遇到 obj[...] 會掛掉
      // const total = this.totalStep
      // for (let step = 1; step <= total; step++) {
      //   this.$refs[`step${step}`]?.init?.()
      //   // this.$refs?.[`step${step + 1}`]?.init?.()
      // }
    },
    async formValidate() {
      const refStep = `step${this.step}`
      const funValidate = this.$refs?.[refStep]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()

      this.init()
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const isValid = await this.formValidate()

      if (!isValid) return

      this.step += 1
    },
    submit() {
      this.$store
        .dispatch('teams/form/submit')
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_created')
          })
          this.close()
          this.init()
          this.$emit('successAdded')
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.team-adder-stepper {
  height: calc(100% - 84px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  ::v-deep .step-title {
    font-size: 1.375rem;
  }

  .v-stepper__items {
    height: 100%;

    .stepper-content {
      padding: 0;
      height: 100%;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }
}

.v-card__actions {
  & .v-btn.v-btn {
    padding: 4px 1.875rem;
    font-size: 1.25rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: normal;

    &.btn-cancel {
      color: #343843;
      border: solid 1px #ddd;
    }
  }
}
</style>
