<template>
  <v-card
    elevation="0"
    color="transparent"
    dark
    style="position: unset; padding-top: 142px; padding-right: 102px; padding-left: 102px;"
  >
    <h3 class="step-1-title mb-6">
      <span v-if="isEditModel">{{ $t('page.teams.edit_team_title') }}</span>
      <span v-else>{{ $t('page.teams.add_team_title') }}</span>
    </h3>
    <v-form ref="form" @submit.prevent>
      <v-row no-gutters style="max-width: 340px;">
        <v-col>
          <v-text-field
            v-model.trim="groupName"
            class="mb-4"
            :rules="rulesGroupName"
            solo
            flat
            light
            :placeholder="$t('page.teams.placeholder_team_name')"
          />
        </v-col>
      </v-row>
    </v-form>

    <p class="pl-4 font-weight-regular">
      {{ $t('page.teams.add_team_content') }}
    </p>

    <!-- <div class="logo-img" /> -->
  </v-card>
</template>

<script>
import { validateRequired, validateDuplicate } from '@/assets/js/validate'

import { mapGetters, mapState } from 'vuex'

const rulesGroupName = vm => [
  validateRequired,
  validateDuplicate(vm.groupNames)
]

export default {
  name: 'TeamAdderStep1',

  computed: {
    ...mapState({
      teams: state => state.teams.teams,
      formData: state => state.teams.form.formData,
      currentEditTeam: state => state.teams.form.currentEditTeam
    }),
    ...mapGetters({
      isEditModel: 'teams/form/isEditModel'
    }),
    rulesGroupName,

    groupNames() {
      return this.teams
        .map(team => team.name)
        .filter(name => name !== this.currentEditTeam?.name)
    },

    groupName: {
      get() {
        return this.formData.groupName
      },
      set(newVal) {
        this.$store.dispatch('teams/form/setFormData', {
          groupName: newVal
        })
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.$refs.form.resetValidation()
    },
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.step-1-title {
  font-size: 2.25rem;
  font-weight: normal;
}

// .logo-img {
//   width: 208px;
//   height: 112px;
//   background-image: url('../../assets/images/logo.png');
//   background-size: cover;
//   opacity: 0.35;

//   position: absolute;
//   right: 92px;
//   bottom: 36px;
// }
</style>
