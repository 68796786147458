<template>
  <div style="height: 100%;">
    <v-row no-gutters justify="center">
      <h3 style="font-size: 1.375rem;">
        {{ $t('page.teams.manage_member_title') }}
      </h3>
    </v-row>

    <v-divider class="mt-3 mb-6" />

    <div style=" overflow-y: auto;height: calc(100% - 68px);">
      <v-hover
        v-for="(member, iMember) in members"
        v-slot="{ hover }"
        :key="`${member.name}-${iMember}`"
      >
        <v-row class="member-row" align="center" no-gutters>
          <v-col cols="auto">
            <v-icon class="mb-1" color="primary" small>
              mdi-account-circle
            </v-icon>
          </v-col>
          <v-col class="ml-2">
            <span>{{ member.name }}</span>
          </v-col>
          <v-col
            class="primary--text text-truncate"
            cols="auto"
            style="font-size: 0.875rem;"
          >
            <span>{{ member.email }}</span>
          </v-col>
          <v-expand-x-transition>
            <div
              v-if="hover"
              class="member-remove d-flex transition-fast-in-fast-out white"
              style="height: 100%;"
            >
              <v-btn color="error" height="100%" text @click="remove(member)">
                {{ $t('cancel_joined') }}
              </v-btn>
            </div>
          </v-expand-x-transition>
        </v-row>
      </v-hover>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TeamJoinedMembers',

  computed: {
    ...mapState({
      formData: state => state.teams.form.formData
    }),

    members: {
      get() {
        return this.formData.members
      },
      set(newVal) {
        this.$store.dispatch('teams/form/setFormData', {
          members: newVal
        })
      }
    }
  },

  methods: {
    remove(member) {
      this.members = this.members.filter(m => m.id !== member.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.member-row {
  position: relative;
  padding: 5px 12px;
  color: #4a4e58;
  border: 1px solid #ddd;
  border-radius: 2px;

  &:not(:first-child) {
    margin-top: 4px;
  }

  .member-remove {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.16);
  }
}
</style>
